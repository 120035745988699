<template>
	<v-navigation-drawer v-model="advanceSearch" fixed width="600" right temporary stateless>
		<div class="drawer-wrapper advance-filter">
			<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
				<div class="font-level-3-bold">Update Account Information</div>
				<v-spacer></v-spacer>
				<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
				<v-btn
					tile
					depressed
					color="white--text"
					:disabled="!formValid || pageLoading"
					class="blue darken-4"
					v-on:click="updatePersonelInfo"
				>
					Save
				</v-btn>
			</v-flex>
			<div class="drawer-content pt-5">
				<v-form class="form-search px-5 mb-3" @submit="updatePersonelInfo" v-model="formValid">
					<div class="font-level-3-bold pb-3 primary--text">User Information</div>
					<v-row>
						<v-col cols="12" lg="3" md="3">
							<label for="first-name" class="btx-label mt-2 required">First Name</label>
						</v-col>
						<v-col cols="12" lg="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="first-fame"
								placeholder="First Name"
								v-model="personal_info.first_name"
								:class="{
									required: !personal_info.first_name,
								}"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="last-name" class="btx-label mt-2">Last Name</label>
						</v-col>
						<v-col cols="12" lg="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="last-name"
								placeholder="Last Name"
								v-model="personal_info.last_name"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="email" class="btx-label mt-2 required">Email</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								type="email"
								append-icon="mdi-pencil"
								outlined
								class="mt-0"
								hide-details
								id="email"
								placeholder="Email"
								v-model="personal_info.email"
								:class="{
									required: !personal_info.email,
								}"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="role" class="btx-label mt-2">Role</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								append-icon="mdi-lock"
								outlined
								disabled
								class="mt-0"
								hide-details
								id="role"
								placeholder=""
								v-model="personal_info.role"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="profile" class="btx-label mt-2">Profile</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								append-icon="mdi-lock"
								outlined
								disabled
								class="mt-0"
								hide-details
								id="profile"
								placeholder="Profile"
								v-model="personal_info.profile"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="alias" class="btx-label mt-2">Display Name</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="alias"
								placeholder="Display Name"
								v-model="personal_info.display_name"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="phone" class="btx-label mt-2">Phone</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<!-- <v-text-field
								outlined
								class="mt-0"
								hide-details
								id="phone"
								placeholder="Phone"
								v-model="personal_info.phone_number"
							>
							</v-text-field> -->

							<PhoneTemplate
								hide-details
								placeholder="Phone"
								v-model="personal_info.phone_number"
								:loading="pageLoading"
								@loading="($event) => (pageLoading = $event)"
								@validity="phoneValid($event)"
								@clear="personal_info.phone_number = null"
							>
							</PhoneTemplate>
						</v-col>
						<!-- <v-col cols="12" lg="3" md="3">
							<label for="mobile" class="btx-label mt-2">Mobile</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="mobile"
								placeholder="Mobile"
								v-model="personal_info.mobile_number"
							>
							</v-text-field>
						</v-col> -->
						<v-col cols="12" lg="3" md="3">
							<label for="website" class="btx-label mt-2">Website</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="website"
								placeholder="Website"
								v-model="personal_info.website"
								:rules="[vrules.validUrl(personal_info.website, 'Website', true /* No Required */)]"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="fax" class="btx-label mt-2">Fax</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="fax"
								placeholder="Fax"
								v-model="personal_info.fax"
								:rules="[vrules.charCount(personal_info.fax, 'Fax', [8, 16])]"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="date-of-birth" class="btx-label mt-2">Date of Birth</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<!-- <v-text-field
								outlined
								class="mt-0"
								hide-details
								id="date-of-birth"
								placeholder="DD:MM:YYYY"
								v-model="personal_info.date_of_birth"
							>
							</v-text-field> -->

							<DatePicker
								hide-details
								clearable
								hideTopMargin
								:disabled="pageLoading"
								:loading="pageLoading"
								id="date-of-birth"
								placeholder="DD:MM:YYYY"
								v-model="personal_info.date_of_birth"
							>
							</DatePicker>
						</v-col>
					</v-row>
					<div class="font-level-3-bold py-3 primary--text">Address Information</div>
					<v-row>
						<v-col cols="12" lg="3" md="3">
							<label for="street" class="btx-label mt-2">Street</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="street"
								placeholder="Street"
								v-model="personal_info.address"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="city" class="btx-label mt-2">City</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="city"
								placeholder="City"
								v-model="personal_info.city"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="state" class="btx-label mt-2">State</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="state"
								placeholder="State"
								v-model="personal_info.state"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="zip-code" class="btx-label mt-2">Zip Code</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="zip-code"
								@keypress="allowInput($event, 'number')"
								placeholder="Zip Code"
								v-model="personal_info.postal_code"
								@paste="disablePaste"
							>
							</v-text-field>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="country" class="btx-label mt-2">Country</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-text-field
								outlined
								class="mt-0"
								hide-details
								id="country"
								placeholder="Country"
								v-model="personal_info.country"
							>
							</v-text-field>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
//import Datepicker from "@/view/components/Datepicker";
import { PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { GET_PROFILE } from "@/core/services/store/profile.module";
import { mapGetters } from "vuex";
import PhoneTemplate from "@/view/components/Phone.vue";
import DatePicker from "@/view/components/DatePicker";
export default {
	name: "AccountInfoDrawer",
	props: {
		advanceSearch: {
			type: Boolean,
			default: false,
		},
		/* userId: {
			type: Number,
		}, */
	},

	data() {
		return {
			user_id: null,
			personal_info: {
				first_name: null,
				last_name: null,
				email: null,
				role: null,
				profile: null,
				display_name: null,
				phone_number: null,
				mobile_number: null,
				website: null,
				fax: null,
				date_of_birth: null,
				address: null,
				city: null,
				state: null,
				postal_code: null,
				country: null,
			},
			pageLoading: false,
			formValid: true,
		};
	},
	watch: {
		getProfile: {
			handler(param) {
				this.personal_info.first_name = param.first_name;
				this.personal_info.last_name = param.last_name;
				this.personal_info.email = param.email;
				this.personal_info.role = param.role_type;
				this.personal_info.profile = param.profile;
				this.personal_info.display_name = param.display_name;
				this.personal_info.phone_number = param.phone_number;
				this.personal_info.mobile_number = param.mobile_number;
				this.personal_info.website = param.website;
				this.personal_info.fax = param.fax;
				this.personal_info.address = param.address;
				this.personal_info.fax = param.fax;
				this.personal_info.date_of_birth = param.date_of_birth;
				this.personal_info.address = param.address;
				this.personal_info.city = param.city;
				this.personal_info.state = param.state;
				this.personal_info.city = param.city;
				this.personal_info.postal_code = param.postal_code;
				this.personal_info.country = param.country;
			},
		},
	},
	methods: {
		phoneValid(validity) {
			this.formValid = validity;
		},
		updatePersonelInfo() {
			this.$store
				.dispatch(PUT, {
					url: `personel-info-setting`,
					data: this.personal_info,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Account info updated successfully.",
						},
					]);
					this.updateProfile();
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getUserProfile() {
			this.$store.dispatch(GET_PROFILE);
		},
		updateProfile() {
			this.$store
				.dispatch(GET_PROFILE, { url: "user" })
				.then(() => {})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {});
		},
	},
	components: {
		PhoneTemplate,
		DatePicker,
	},
	mounted() {
		this.personal_info.first_name = this.getProfile.first_name;
		this.personal_info.last_name = this.getProfile.last_name;
		this.personal_info.email = this.getProfile.email;
		this.personal_info.role = this.getProfile.role_type;
		this.personal_info.profile = this.getProfile.profile;
		this.personal_info.display_name = this.getProfile.display_name;
		this.personal_info.phone_number = this.getProfile.phone_number;
		this.personal_info.mobile_number = this.getProfile.mobile_number;
		this.personal_info.website = this.getProfile.website;
		this.personal_info.fax = this.getProfile.fax;
		this.personal_info.address = this.getProfile.address;
		this.personal_info.fax = this.getProfile.fax;
		this.personal_info.date_of_birth = this.getProfile.date_of_birth;
		this.personal_info.address = this.getProfile.address;
		this.personal_info.city = this.getProfile.city;
		this.personal_info.state = this.getProfile.state;
		this.personal_info.city = this.getProfile.city;
		this.personal_info.postal_code = this.getProfile.postal_code;
		this.personal_info.country = this.getProfile.country;
	},
	computed: {
		...mapGetters(["getProfile"]),
		getFullName() {
			return "";
		},
	},
};
</script>
