<template>
	<div class="customer-template-setting">
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">Personal Setting</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn class="" dense tile v-on:click="goBack()">
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>

		<v-sheet class="personalsetting">
			<v-row>
				<!-- <v-col cols="12" lg="3" md="3" class="py-0"></v-col> -->
				<v-col cols="12" lg="12" md="12" class="py-0">
					<div class="mx-auto my-3 px-6 py-6 blue lighten-5">
						<table>
							<tbody>
								<tr>
									<td style="vertical-align: top">
										<div
											class=""
											style="width: 94px; height: 94px; border-radius: 50%; overflow: hidden; margin: 0px auto"
											v-if="getProfile.profile_img"
										>
											<v-img
												style="cursor: default; border: 1px solid rgba(128, 128, 128, 0.1)"
												max-height="100"
												max-width="100"
												:src="getProfile.profile_img"
												border="0"
											></v-img>
										</div>
										<div
											class=""
											style="width: 94px; height: 94px; border-radius: 50%; overflow: hidden; margin: 0px auto"
											v-else
										>
											<v-img
												style="cursor: default; border: 1px solid rgba(128, 128, 128, 0.1)"
												max-height="100"
												max-width="100"
												:src="image"
												border="0"
											></v-img>
										</div>
										<div class="pointer Primary--text text-center primary--text" v-on:click="selectFile()">
											Change
										</div>
									</td>
									<td style="padding-left: 15px">
										<div>
											<span class="sub-heading text-capitalize me-2">{{ getProfile.first_name }}</span>
											<span>
												<v-chip color="#e57800" outlined> {{ getProfile.display_name }} </v-chip>
											</span>
											<span class="edit-icon">
												<v-btn icon small fab class="ml-3">
													<v-icon color="primary" v-on:click="AccountInfoDrawer = true">mdi-pencil</v-icon>
												</v-btn>
											</span>
										</div>
										<div>
											<span class="pr-1 text-muted">{{ getProfile.profile }}</span>
											<span class="pr-1 text-muted">at</span>
											<span class="pr-1 pointer text-muted">bthrust</span>
										</div>
										<div style="margin-bottom: 15px"><span class="red"></span></div>
										<div class="d-flex align-center">
											<div class="d-flex fw-500">
												<v-icon class="me-3" color="primary"> mdi-email-outline</v-icon>
												<span class="me-5 fw-500 text-lowercase">
													<ShowValue :object="getProfile" object-key="email" :click-to-copy="true"></ShowValue>
													<!-- {{ getProfile.email }} -->
												</span>
											</div>
											<div class="d-flex fw-500">
												<v-icon class="me-3" color="primary"> mdi-phone</v-icon>
												<!-- <span class="fw-500">{{ getProfile.phone_number }}</span> -->
												<ShowValue
													:object="getProfile"
													object-key="phone_number"
													:click-to-copy="true"
												></ShowValue>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<div class="showhidedetail" style="margin-top: 20px">
							<a href="#" class="primary--text" v-on:click="showHideDetail = !showHideDetail">
								<span v-if="showHideDetail">Hide Details<v-icon>mdi-chevron-up</v-icon></span>
								<span v-else>Show Details<v-icon>mdi-chevron-down</v-icon></span>
							</a>
						</div>

						<table v-if="showHideDetail">
							<tbody>
								<tr>
									<td>
										<div class="sub-heading text-capitalize mt-2">User Information</div>
									</td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>First Name</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.first_name">{{ getProfile.first_name }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no first name</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Last Name</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.last_name">{{ getProfile.last_name }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no last name</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Email</span></td>
									<td class="fw-500 py-2" v-if="getProfile.email">
										<a href="#" class="primary--text">{{ getProfile.email }}</a>
									</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no email</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Role</span></td>
									<td class="fw-500 py-2" v-if="getProfile.role">{{ getProfile.role_type }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no role</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Profile</span></td>
									<td class="fw-500 py-2" v-if="getProfile.profile">{{ getProfile.profile }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no profile</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Added By</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.added_at">
										{{ getProfile.first_name }},
										<span class="text-muted">{{ formatDate(getProfile.added_at) }}</span>
									</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no added at</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Dispaly Name</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.display_name">{{ getProfile.display_name }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no display name</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Phone</span></td>
									<td class="fw-500 py-2" v-if="getProfile.phone_number">{{ getProfile.phone_number }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no phone</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Website</span></td>
									<td class="fw-500 py-2" v-if="getProfile.website">{{ getProfile.website }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no website</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Fax</span></td>
									<td class="fw-500 py-2" v-if="getProfile.fax">{{ getProfile.fax }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no fax</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Date of Birth</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.date_of_birth">
										{{ formatDate(getProfile.date_of_birth) }}
									</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no date of birth</em></td>
								</tr>
								<div class="font-level-3-bold mt-2">Address Information</div>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Street</span></td>
									<td class="fw-500 py-2" v-if="getProfile.address">{{ getProfile.address }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no street</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>City</span></td>
									<td class="fw-500 py-2" v-if="getProfile.city">{{ getProfile.city }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no city</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>State</span></td>
									<td class="fw-500 py-2" v-if="getProfile.state">{{ getProfile.state }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no state</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Zip Code</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.postal_code">{{ getProfile.postal_code }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no zip code</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right"><span>Country</span></td>
									<td class="fw-500 py-2" v-if="getProfile.country">{{ getProfile.country }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no country</em></td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="mx-auto my-3 px-6 py-3" width="100%">
						<table>
							<tbody>
								<tr>
									<td>
										<div class="sub-heading text-capitalize">Locale Information</div>
									</td>
									<td class="edit-icon">
										<v-btn icon small @click="Localdrawer = true">
											<v-icon color="primary">mdi-pencil</v-icon>
										</v-btn>
									</td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Language</span>
									</td>
									<td class="fw-500 text-capitalize py-2" v-if="getProfile.language">
										{{ getProfile.language }}
									</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no language</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Country Locale</span>
									</td>
									<td class="fw-500 text-capitalize py-2" v-if="getProfile.country_locale">
										{{ getProfile.country_locale }}
									</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no language</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Date Format</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.date_format">{{ getProfile.date_format }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no date format</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Time Format</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.time_format">{{ getProfile.time_format }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no time format</em></td>
								</tr>
								<tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Time Zone</span>
									</td>
									<td class="fw-500 py-2" v-if="getProfile.time_zone">{{ getProfile.time_zone }}</td>
									<td class="fw-500 py-2" v-else><em class="text-muted">no time zone</em></td>
								</tr>
								<!-- <tr>
									<td class="py-2 px-8 fw-500 text-muted" style="text-align: right">
										<span>Number Format</span>
									</td>
									<td class="fw-500 py-2">123,456.789</td>
								</tr> -->
							</tbody>
						</table>
					</div>
					<!-- <div class="mx-auto my-3 px-6 py-3" width="100%">
					<div class="sub-heading text-capitalize">Display Name Format & Preferences</div>
					<p class="text-muted my-1">
						<span>It applies to fullname in column of list view, lookup fields and user name</span>
					</p>
					<table>
						<tbody>
							<tr>
								<td>
									<v-row>
										<v-col cols="12" lg="4">
											<label for="name-format" class="btx-label mt-2">Name Format</label>
										</v-col>
										<v-col cols="12" lg="8">
											<v-autocomplete
												outlined
												class="mt-0"
												hide-details
												id="name-format"
												:items="name_formatList"
												v-model="name_format"
											>
											</v-autocomplete>
										</v-col>
										<v-col cols="12" lg="4">
											<label for="order-preference" class="btx-label mt-2">Sort order preference</label>
										</v-col>
										<v-col cols="12" lg="8">
											<v-autocomplete
												outlined
												class="mt-0"
												hide-details
												id="order-preference"
												placeholder=""
												:items="order_preferenceList"
												v-model="order_preference"
											>
											</v-autocomplete>
										</v-col>
									</v-row>
								</td>
							</tr>
						</tbody>
					</table>
				</div> -->
					<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
				</v-col>
			</v-row>
			<AccountInfoDrawer
				v-if="AccountInfoDrawer"
				:advanceSearch="AccountInfoDrawer"
				:user-id="getProfile.id"
				@close="AccountInfoDrawer = false"
			></AccountInfoDrawer>
			<LocalDrawer
				:advanceSearch="Localdrawer"
				@success="getUserProfile"
				@close="Localdrawer = false"
			></LocalDrawer>
		</v-sheet>
	</div>
</template>
<script>
import LocalDrawer from "@/view/module/setting/personal/LocalDrawer.vue";
import AccountInfoDrawer from "@/view/module/setting/personal/AccountInfoDrawer.vue";
import { GET_PROFILE } from "@/core/services/store/profile.module";
import { mapGetters } from "vuex";
import { POST } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import ShowValue from "@/view/components/ShowValue";
export default {
	name: "PersonalSettings",
	data() {
		return {
			showHideDetail: false,
			AccountInfoDrawer: false,
			Localdrawer: false,
			image: "https://contacts.zoho.in/file?ID=60015080241&fs=thumb",
			name_format: "Last Name, Salution, First Name",
			name_formatList: ["Last Name, Salution, First Name"],
			order_preference: "First Name, Last Name",
			order_preferenceList: ["First Name, Last Name", "Last Name, First Name"],
		};
	},
	components: {
		LocalDrawer,
		AccountInfoDrawer,
		ShowValue,
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getUserProfile() {
			this.$store.dispatch(GET_PROFILE);
		},
		selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		},
		uploadFile(e) {
			let formData = new FormData();
			formData.append("files", e);
			this.$store
				.dispatch(POST, { url: `image-change`, data: formData })
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Image updated successfully.",
						},
					]);
					this.getUserProfile();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	computed: {
		...mapGetters(["getProfile"]),

		getFullName() {
			return "";
		},
	},
};
</script>
