<template>
	<v-navigation-drawer v-model="advanceSearch" fixed width="600" right temporary stateless>
		<div class="drawer-wrapper advance-filter">
			<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
				<div class="font-level-3-bold">Locale Information</div>
				<v-spacer></v-spacer>
				<v-btn depressed tile class="mr-2" @click="$emit('close', true)">Cancel</v-btn>
				<v-btn
					tile
					depressed
					color="white--text"
					:disabled="!formValid"
					class="blue darken-4"
					@click="addLocaleInfo"
				>
					Save
				</v-btn>
			</v-flex>
			<div class="drawer-content pt-5">
				<v-form
					class="form-search px-5 mb-3"
					v-model.trim="formValid"
					lazy-validation
					@submit.stop.prevent="addLocaleInfo"
				>
					<v-row>
						<v-col cols="12" lg="3" md="3">
							<label for="language" class="btx-label required mt-2">Language</label>
						</v-col>
						<v-col cols="12" lg="9">
							<v-autocomplete
								outlined
								class="mt-0"
								hide-details
								id="language"
								placeholder=""
								:items="language_List"
								item-text="text"
								item-value="value"
								v-model="locale_info.language"
								:rules="[vrules.required(locale_info.language, 'Language')]"
								:class="{
									required: !locale_info.language,
								}"
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="country-location" class="btx-label required mt-2">Country Locale</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-autocomplete
								outlined
								class="mt-0"
								hide-details
								id="country-location"
								placeholder=""
								:items="country_locationList"
								item-text="text"
								item-value="value"
								v-model="locale_info.country_locale"
								:rules="[vrules.required(locale_info.country_locale, 'Country Locale')]"
								:class="{
									required: !locale_info.country_locale,
								}"
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="date-format" class="btx-label mt-2">Date Format</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-autocomplete
								outlined
								class="mt-0"
								hide-details
								id="date-format"
								placeholder=""
								:items="date_formatList"
								item-text="text"
								item-value="value"
								v-model="locale_info.date_format"
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="time-format" class="btx-label mt-2">Time Format</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-autocomplete
								outlined
								class="mt-0"
								hide-details
								id="time-format"
								placeholder=""
								:items="time_formatList"
								item-text="text"
								item-value="value"
								v-model="locale_info.time_format"
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="time-zone" class="btx-label mt-2">Time Zone</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-autocomplete
								outlined
								class="mt-0"
								hide-details
								id="time-format"
								placeholder=""
								:items="time_zoneLists"
								item-text="text"
								item-value="value"
								v-model="locale_info.time_zone"
							>
							</v-autocomplete>
						</v-col>
					</v-row>
					<!--div class="font-level-3-bold py-3 primary--text">Number Format</div>
					 <v-row>
						<v-col cols="12" lg="3" md="3">
							<label for="grouping" class="btx-label mt-2">Grouping</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-autocomplete
								outlined
								class="mt-0"
								hide-details
								id="grouping"
								placeholder=""
								:items="groupingList"
								v-model="grouping"
							>
							</v-autocomplete>
						</v-col>
						<v-col cols="12" lg="3" md="3">
							<label for="decimal" class="btx-label mt-2">Decimal</label>
						</v-col>
						<v-col cols="12" lg="9" md="9">
							<v-autocomplete
								outlined
								class="mt-0"
								hide-details
								id="decimal"
								placeholder=""
								:items="decimalList"
								v-model="decimal"
							>
							</v-autocomplete>
						</v-col>
					</v-row> -->
				</v-form>
			</div>
		</div>
	</v-navigation-drawer>
</template>
<script>
import { GET, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { GET_PROFILE } from "@/core/services/store/profile.module";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
	name: "LocalDrawer",
	props: {
		advanceSearch: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			language_List: [],
			time_formatList: [],
			date_formatList: [
				{
					value: "MMMM Do YYYY",
					text: moment().format("MMMM Do YYYY"),
				},
				{
					value: "Do MMMM YYYY",
					text: moment().format("Do MMMM YYYY"),
				},
				{
					value: "MMM Do YY",
					text: moment().format("MMM Do YY"),
				},
				{
					value: "Do MMM YY",
					text: moment().format("Do MMM YY"),
				},
				{
					value: "DD/MM/YYYY",
					text: moment().format("DD/MM/YYYY"),
				},
				{
					value: "MM/DD/YYYY",
					text: moment().format("MM/DD/YYYY"),
				},
				{
					value: "YYYY-DD-MM",
					text: moment().format("YYYY-DD-MM"),
				},
			],
			country_locationList: [],
			time_zoneLists: [],
			locale_info: {
				language: null,
				country_locale: null,
				date_format: null,
				time_format: null,
				time_zone: null,
			},
			formValid: true,
		};
	},
	watch: {
		getProfile: {
			handler(param) {
				this.locale_info.language = param.language;
				this.locale_info.country_locale = param.country_locale;
				this.locale_info.date_format = param.date_format;
				this.locale_info.time_format = param.time_format;
				this.locale_info.time_format = this.locale_info.time_format;
				this.locale_info.time_zone = param.time_zone;
			},
		},
	},
	components: {},
	methods: {
		getUserProfile() {
			this.$store.dispatch(GET_PROFILE);
		},
		getFieldSetting() {
			this.$store
				.dispatch(GET, { url: `setting-field` })
				.then((data) => {
					this.language_List = data.language;
					this.country_locationList = data.country;
					this.time_formatList = data.time_format;
					this.time_zoneLists = data.zone;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		addLocaleInfo() {
			this.$store
				.dispatch(PUT, {
					url: `locale-info-setting`,
					data: this.locale_info,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Locale information updated successfully.",
						},
					]);
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	mounted() {
		this.getFieldSetting();
		this.locale_info.language = this.getProfile.getlanguage;
		this.locale_info.country_locale = this.getProfile.country_locale;
		this.locale_info.date_format = this.getProfile.date_format;
		this.locale_info.time_format = this.getProfile.time_format;
		this.locale_info.time_zone = this.getProfile.time_zone;
	},
	computed: {
		...mapGetters(["getProfile"]),

		getFullName() {
			return "";
		},
	},
};
</script>
